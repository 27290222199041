<template>
    <div>
        <el-select
            :value="selected"
            @input="dispatch"
            :placeholder="placeholder || $t('message.status')"
            filterable
            clearable
            :size="size"
            class="w-100"
        >
            <el-option
                v-for="(status, index) in statuses"
                :key="'statuses-' + index"
                :label="status"
                :value="status"
            ></el-option>
        </el-select>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    props: {
        size: {
            default: "small",
        },
        placeholder: {
            default: null,
        },
        id: {
            default: null,
        },
    },
    watch: {
        id: {
            handler: function () {
                this.selected = this.id;
            },
            immediate: true,
        },
    },
    data() {
        return {
            statuses: [
                'active', 'disactive'
            ],
            selected: null,
        };
    },
    // mounted() {
    //     if (this.statuses && this.statuses.length === 0) this.updateInventory();
    // },
    computed: {
        // ...mapGetters({
        //     statuses: "statuses/inventory",
        // }),
    },
    methods: {
        // ...mapActions({
        //     updateInventory: "statuses/inventory",
        // }),
        dispatch(e) {
            this.$emit("input", e);
            this.selected = e;
        },
    },
};
</script>
