<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.telegramStatus')" filterable clearable
      :size="size" class="w-100">
      <el-option v-for="(telegramStatus,index) in telegramStatuses" :key="'telegramStatuses-' + index" :label="telegramStatus.name" :value="telegramStatus.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      if (this.telegramStatuses && this.telegramStatuses.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
        telegramStatuses: 'users/getUserTelegramStatuses'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'users/getUserTelegramStatuses'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
